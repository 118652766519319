import React from 'react'
import Menu from "../Menu/Menu"
import './Friends.css'

export default function Friends() {
    return (
        <>
          <Menu/>  
        </>
    )
}
