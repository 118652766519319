import React from 'react';
import Menu from "../Menu/Menu";

export default function ScoreCard() {

    return (
        <div className='bg'>
            <div>
                <Menu/>
                <h2>ScoreCard</h2>
            </div>

        </div>
    );
}